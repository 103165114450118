@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.wallet-adapter-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: 0 24px;
    border-radius: 4px;
}
.wallet-adapter-button-trigger {
    background-color: #512da8;
}
.wallet-adapter-button:not([disabled]):focus-visible {
    outline-color: #fff;
}
.wallet-adapter-button:not([disabled]):hover {
    background-color: #1a1f2e;
}
.wallet-adapter-button[disabled] {
    background: #404144;
    color: #999;
    cursor: not-allowed;
}
.wallet-adapter-button-end-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-start-icon img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}
.wallet-adapter-button-end-icon {
    margin-left: 12px;
}
.wallet-adapter-button-start-icon {
    margin-right: 12px;
}
.wallet-adapter-collapse {
    width: 100%;
}
.wallet-adapter-dropdown {
    position: relative;
    display: inline-block;
}
.wallet-adapter-dropdown-list {
    position: absolute;
    z-index: 99;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 10px;
    padding: 10px;
    top: 100%;
    right: 0;
    margin: 0;
    list-style: none;
    background: #2c2d30;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
    font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.wallet-adapter-dropdown-list-active {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
}
.wallet-adapter-dropdown-list-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    height: 37px;
    color: #fff;
}
.wallet-adapter-dropdown-list-item:not([disabled]):hover {
    background-color: #1a1f2e;
}
.wallet-adapter-modal-collapse-button svg {
    align-self: center;
    fill: #999;
}
.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active
    svg {
    transform: rotate(180deg);
    transition: transform 0.15s ease-in;
}
.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.15s linear;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    overflow-y: auto;
}
.wallet-adapter-modal.wallet-adapter-modal-fade-in {
    opacity: 1;
}
.wallet-adapter-modal-button-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 18px;
    right: 18px;
    padding: 12px;
    cursor: pointer;
    background: #1a1f2e;
    border: none;
    border-radius: 50%;
}
.wallet-adapter-modal-button-close:focus-visible {
    outline-color: #fff;
}
.wallet-adapter-modal-button-close svg {
    fill: #777;
    transition: fill 0.2s ease 0s;
}
.wallet-adapter-modal-button-close:hover svg {
    fill: #fff;
}
.wallet-adapter-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.wallet-adapter-modal-container {
    display: flex;
    margin: 3rem;
    min-height: calc(100vh - 6rem);
    align-items: center;
    justify-content: center;
}
@media (max-width: 480px) {
    .wallet-adapter-modal-container {
        margin: 1rem;
        min-height: calc(100vh - 2rem);
    }
}
.wallet-adapter-modal-wrapper {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1050;
    max-width: 400px;
    border-radius: 10px;
    background: #10141f;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
    font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
    flex: 1;
}
.wallet-adapter-modal-wrapper .wallet-adapter-button {
    width: 100%;
}
.wallet-adapter-modal-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    margin: 0;
    padding: 64px 48px 48px;
    text-align: center;
    color: #fff;
}
@media (max-width: 374px) {
    .wallet-adapter-modal-title {
        font-size: 18px;
    }
}
.wallet-adapter-modal-list {
    margin: 0 0 12px;
    padding: 0;
    width: 100%;
    list-style: none;
}
.wallet-adapter-modal-list .wallet-adapter-button {
    font-weight: 400;
    border-radius: 0;
    font-size: 18px;
}
.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
    width: 28px;
    height: 28px;
}
.wallet-adapter-modal-list .wallet-adapter-button span {
    margin-left: auto;
    font-size: 14px;
    opacity: 0.6;
}
.wallet-adapter-modal-list-more {
    cursor: pointer;
    border: none;
    padding: 12px 24px 24px 12px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #fff;
}
.wallet-adapter-modal-list-more svg {
    transition: all 0.1s ease;
    fill: #fff;
    margin-left: 0.5rem;
}
.wallet-adapter-modal-list-more-icon-rotate {
    transform: rotate(180deg);
}
.wallet-adapter-modal-middle {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 24px;
    box-sizing: border-box;
}
.wallet-adapter-modal-middle-button {
    display: block;
    cursor: pointer;
    margin-top: 48px;
    width: 100%;
    background-color: #512da8;
    padding: 12px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    color: #fff;
}
/* @font-face {
    font-family: __Poppins_45434c;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./assets/_next/static/media/87c72f23c47212b9-s.woff2)
        format("woff2");
    unicode-range: u+0900-097f, u+1cd0-1cf9, u+200c-200d, u+20a8, u+20b9, u+20f0,
        u+25cc, u+a830-a839, u+a8e0-a8ff, u+11b00-11b09;
}
@font-face {
    font-family: __Poppins_45434c;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./assets/_next/static/media/591327bf3b62a611-s.woff2)
        format("woff2");
    unicode-range: u+0100-02af, u+0304, u+0308, u+0329, u+1e00-1e9f, u+1ef2-1eff,
        u+2020, u+20a0-20ab, u+20ad-20c0, u+2113, u+2c60-2c7f, u+a720-a7ff;
}
@font-face {
    font-family: __Poppins_45434c;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./assets/_next/static/media/916d3686010a8de2-s.p.woff2)
        format("woff2");
    unicode-range: u+00??, u+0131, u+0152-0153, u+02bb-02bc, u+02c6, u+02da,
        u+02dc, u+0304, u+0308, u+0329, u+2000-206f, u+2074, u+20ac, u+2122,
        u+2191, u+2193, u+2212, u+2215, u+feff, u+fffd;
} */
@font-face {
    font-family: __Poppins_Fallback_45434c;
    src: local("Arial");
    ascent-override: 92.33%;
    descent-override: 30.78%;
    line-gap-override: 8.79%;
    size-adjust: 113.73%;
}
.__className_45434c {
    font-family: __Poppins_45434c, __Poppins_Fallback_45434c;
    font-weight: 400;
    font-style: normal;
}
.__variable_45434c {
    --font-poppins: "__Poppins_45434c", "__Poppins_Fallback_45434c";
}
[data-rk] .iekbcc0 {
    border: 0;
    box-sizing: border-box;
    font-size: 100%;
    line-height: normal;
    margin: 0;
    padding: 0;
    text-align: left;
    vertical-align: baseline;
    -webkit-tap-highlight-color: transparent;
}
[data-rk] .iekbcc1 {
    list-style: none;
}
[data-rk] .iekbcc2 {
    quotes: none;
}
[data-rk] .iekbcc2:after,
[data-rk] .iekbcc2:before {
    content: "";
}
[data-rk] .iekbcc3 {
    border-collapse: collapse;
    border-spacing: 0;
}
[data-rk] .iekbcc4 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
[data-rk] .iekbcc5 {
    outline: none;
}
[data-rk] .iekbcc5::-moz-placeholder {
    opacity: 1;
}
[data-rk] .iekbcc5::placeholder {
    opacity: 1;
}
[data-rk] .iekbcc6 {
    background-color: transparent;
    color: inherit;
}
[data-rk] .iekbcc7:disabled {
    opacity: 1;
}
[data-rk] .iekbcc7::-ms-expand,
[data-rk] .iekbcc8::-ms-clear {
    display: none;
}
[data-rk] .iekbcc8::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
[data-rk] .iekbcc9 {
    background: 0 0;
    cursor: pointer;
    text-align: left;
}
[data-rk] .iekbcca {
    color: inherit;
    text-decoration: none;
}
[data-rk] .ju367v0 {
    align-items: flex-start;
}
[data-rk] .ju367v2 {
    align-items: flex-end;
}
[data-rk] .ju367v4 {
    align-items: center;
}
[data-rk] .ju367v6 {
    display: none;
}
[data-rk] .ju367v8 {
    display: block;
}
[data-rk] .ju367va {
    display: flex;
}
[data-rk] .ju367vc {
    display: inline;
}
[data-rk] .ju367ve {
    align-self: flex-start;
}
[data-rk] .ju367vf {
    align-self: flex-end;
}
[data-rk] .ju367vg {
    align-self: center;
}
[data-rk] .ju367vh {
    background-size: cover;
}
[data-rk] .ju367vi {
    border-radius: 1px;
}
[data-rk] .ju367vj {
    border-radius: 6px;
}
[data-rk] .ju367vk {
    border-radius: 10px;
}
[data-rk] .ju367vl {
    border-radius: 13px;
}
[data-rk] .ju367vm {
    border-radius: var(--rk-radii-actionButton);
}
[data-rk] .ju367vn {
    border-radius: var(--rk-radii-connectButton);
}
[data-rk] .ju367vo {
    border-radius: var(--rk-radii-menuButton);
}
[data-rk] .ju367vp {
    border-radius: var(--rk-radii-modal);
}
[data-rk] .ju367vq {
    border-radius: var(--rk-radii-modalMobile);
}
[data-rk] .ju367vr {
    border-radius: 25%;
}
[data-rk] .ju367vs {
    border-radius: 9999px;
}
[data-rk] .ju367vt {
    border-style: solid;
}
[data-rk] .ju367vu {
    border-width: 0;
}
[data-rk] .ju367vv {
    border-width: 1px;
}
[data-rk] .ju367vw {
    border-width: 2px;
}
[data-rk] .ju367vx {
    border-width: 4px;
}
[data-rk] .ju367vy {
    cursor: pointer;
}
[data-rk] .ju367vz {
    cursor: none;
}
[data-rk] .ju367v10 {
    pointer-events: none;
}
[data-rk] .ju367v11 {
    pointer-events: all;
}
[data-rk] .ju367v12 {
    min-height: 8px;
}
[data-rk] .ju367v13 {
    min-height: 44px;
}
[data-rk] .ju367v14 {
    flex-direction: row;
}
[data-rk] .ju367v15 {
    flex-direction: column;
}
[data-rk] .ju367v16 {
    font-family: var(--rk-fonts-body);
}
[data-rk] .ju367v17 {
    font-size: 12px;
    line-height: 18px;
}
[data-rk] .ju367v18 {
    font-size: 13px;
    line-height: 18px;
}
[data-rk] .ju367v19 {
    font-size: 14px;
    line-height: 18px;
}
[data-rk] .ju367v1a {
    font-size: 16px;
    line-height: 20px;
}
[data-rk] .ju367v1b {
    font-size: 18px;
    line-height: 24px;
}
[data-rk] .ju367v1c {
    font-size: 20px;
    line-height: 24px;
}
[data-rk] .ju367v1d {
    font-size: 23px;
    line-height: 29px;
}
[data-rk] .ju367v1e {
    font-weight: 400;
}
[data-rk] .ju367v1f {
    font-weight: 500;
}
[data-rk] .ju367v1g {
    font-weight: 600;
}
[data-rk] .ju367v1h {
    font-weight: 700;
}
[data-rk] .ju367v1i {
    font-weight: 800;
}
[data-rk] .ju367v1j {
    gap: 0;
}
[data-rk] .ju367v1k {
    gap: 1px;
}
[data-rk] .ju367v1l {
    gap: 2px;
}
[data-rk] .ju367v1m {
    gap: 3px;
}
[data-rk] .ju367v1n {
    gap: 4px;
}
[data-rk] .ju367v1o {
    gap: 5px;
}
[data-rk] .ju367v1p {
    gap: 6px;
}
[data-rk] .ju367v1q {
    gap: 8px;
}
[data-rk] .ju367v1r {
    gap: 10px;
}
[data-rk] .ju367v1s {
    gap: 12px;
}
[data-rk] .ju367v1t {
    gap: 14px;
}
[data-rk] .ju367v1u {
    gap: 16px;
}
[data-rk] .ju367v1v {
    gap: 18px;
}
[data-rk] .ju367v1w {
    gap: 20px;
}
[data-rk] .ju367v1x {
    gap: 24px;
}
[data-rk] .ju367v1y {
    gap: 28px;
}
[data-rk] .ju367v1z {
    gap: 32px;
}
[data-rk] .ju367v20 {
    gap: 36px;
}
[data-rk] .ju367v21 {
    gap: 44px;
}
[data-rk] .ju367v22 {
    gap: 64px;
}
[data-rk] .ju367v23 {
    gap: -1px;
}
[data-rk] .ju367v24 {
    height: 1px;
}
[data-rk] .ju367v25 {
    height: 2px;
}
[data-rk] .ju367v26 {
    height: 4px;
}
[data-rk] .ju367v27 {
    height: 8px;
}
[data-rk] .ju367v28 {
    height: 12px;
}
[data-rk] .ju367v29 {
    height: 20px;
}
[data-rk] .ju367v2a {
    height: 24px;
}
[data-rk] .ju367v2b {
    height: 28px;
}
[data-rk] .ju367v2c {
    height: 30px;
}
[data-rk] .ju367v2d {
    height: 32px;
}
[data-rk] .ju367v2e {
    height: 34px;
}
[data-rk] .ju367v2f {
    height: 36px;
}
[data-rk] .ju367v2g {
    height: 40px;
}
[data-rk] .ju367v2h {
    height: 44px;
}
[data-rk] .ju367v2i {
    height: 48px;
}
[data-rk] .ju367v2j {
    height: 54px;
}
[data-rk] .ju367v2k {
    height: 60px;
}
[data-rk] .ju367v2l {
    height: 200px;
}
[data-rk] .ju367v2m {
    height: 100%;
}
[data-rk] .ju367v2n {
    height: -moz-max-content;
    height: max-content;
}
[data-rk] .ju367v2o {
    justify-content: flex-start;
}
[data-rk] .ju367v2p {
    justify-content: flex-end;
}
[data-rk] .ju367v2q {
    justify-content: center;
}
[data-rk] .ju367v2r {
    justify-content: space-between;
}
[data-rk] .ju367v2s {
    justify-content: space-around;
}
[data-rk] .ju367v2t {
    text-align: left;
}
[data-rk] .ju367v2u {
    text-align: center;
}
[data-rk] .ju367v2v {
    text-align: inherit;
}
[data-rk] .ju367v2w {
    margin-bottom: 0;
}
[data-rk] .ju367v2x {
    margin-bottom: 1px;
}
[data-rk] .ju367v2y {
    margin-bottom: 2px;
}
[data-rk] .ju367v2z {
    margin-bottom: 3px;
}
[data-rk] .ju367v30 {
    margin-bottom: 4px;
}
[data-rk] .ju367v31 {
    margin-bottom: 5px;
}
[data-rk] .ju367v32 {
    margin-bottom: 6px;
}
[data-rk] .ju367v33 {
    margin-bottom: 8px;
}
[data-rk] .ju367v34 {
    margin-bottom: 10px;
}
[data-rk] .ju367v35 {
    margin-bottom: 12px;
}
[data-rk] .ju367v36 {
    margin-bottom: 14px;
}
[data-rk] .ju367v37 {
    margin-bottom: 16px;
}
[data-rk] .ju367v38 {
    margin-bottom: 18px;
}
[data-rk] .ju367v39 {
    margin-bottom: 20px;
}
[data-rk] .ju367v3a {
    margin-bottom: 24px;
}
[data-rk] .ju367v3b {
    margin-bottom: 28px;
}
[data-rk] .ju367v3c {
    margin-bottom: 32px;
}
[data-rk] .ju367v3d {
    margin-bottom: 36px;
}
[data-rk] .ju367v3e {
    margin-bottom: 44px;
}
[data-rk] .ju367v3f {
    margin-bottom: 64px;
}
[data-rk] .ju367v3g {
    margin-bottom: -1px;
}
[data-rk] .ju367v3h {
    margin-left: 0;
}
[data-rk] .ju367v3i {
    margin-left: 1px;
}
[data-rk] .ju367v3j {
    margin-left: 2px;
}
[data-rk] .ju367v3k {
    margin-left: 3px;
}
[data-rk] .ju367v3l {
    margin-left: 4px;
}
[data-rk] .ju367v3m {
    margin-left: 5px;
}
[data-rk] .ju367v3n {
    margin-left: 6px;
}
[data-rk] .ju367v3o {
    margin-left: 8px;
}
[data-rk] .ju367v3p {
    margin-left: 10px;
}
[data-rk] .ju367v3q {
    margin-left: 12px;
}
[data-rk] .ju367v3r {
    margin-left: 14px;
}
[data-rk] .ju367v3s {
    margin-left: 16px;
}
[data-rk] .ju367v3t {
    margin-left: 18px;
}
[data-rk] .ju367v3u {
    margin-left: 20px;
}
[data-rk] .ju367v3v {
    margin-left: 24px;
}
[data-rk] .ju367v3w {
    margin-left: 28px;
}
[data-rk] .ju367v3x {
    margin-left: 32px;
}
[data-rk] .ju367v3y {
    margin-left: 36px;
}
[data-rk] .ju367v3z {
    margin-left: 44px;
}
[data-rk] .ju367v40 {
    margin-left: 64px;
}
[data-rk] .ju367v41 {
    margin-left: -1px;
}
[data-rk] .ju367v42 {
    margin-right: 0;
}
[data-rk] .ju367v43 {
    margin-right: 1px;
}
[data-rk] .ju367v44 {
    margin-right: 2px;
}
[data-rk] .ju367v45 {
    margin-right: 3px;
}
[data-rk] .ju367v46 {
    margin-right: 4px;
}
[data-rk] .ju367v47 {
    margin-right: 5px;
}
[data-rk] .ju367v48 {
    margin-right: 6px;
}
[data-rk] .ju367v49 {
    margin-right: 8px;
}
[data-rk] .ju367v4a {
    margin-right: 10px;
}
[data-rk] .ju367v4b {
    margin-right: 12px;
}
[data-rk] .ju367v4c {
    margin-right: 14px;
}
[data-rk] .ju367v4d {
    margin-right: 16px;
}
[data-rk] .ju367v4e {
    margin-right: 18px;
}
[data-rk] .ju367v4f {
    margin-right: 20px;
}
[data-rk] .ju367v4g {
    margin-right: 24px;
}
[data-rk] .ju367v4h {
    margin-right: 28px;
}
[data-rk] .ju367v4i {
    margin-right: 32px;
}
[data-rk] .ju367v4j {
    margin-right: 36px;
}
[data-rk] .ju367v4k {
    margin-right: 44px;
}
[data-rk] .ju367v4l {
    margin-right: 64px;
}
[data-rk] .ju367v4m {
    margin-right: -1px;
}
[data-rk] .ju367v4n {
    margin-top: 0;
}
[data-rk] .ju367v4o {
    margin-top: 1px;
}
[data-rk] .ju367v4p {
    margin-top: 2px;
}
[data-rk] .ju367v4q {
    margin-top: 3px;
}
[data-rk] .ju367v4r {
    margin-top: 4px;
}
[data-rk] .ju367v4s {
    margin-top: 5px;
}
[data-rk] .ju367v4t {
    margin-top: 6px;
}
[data-rk] .ju367v4u {
    margin-top: 8px;
}
[data-rk] .ju367v4v {
    margin-top: 10px;
}
[data-rk] .ju367v4w {
    margin-top: 12px;
}
[data-rk] .ju367v4x {
    margin-top: 14px;
}
[data-rk] .ju367v4y {
    margin-top: 16px;
}
[data-rk] .ju367v4z {
    margin-top: 18px;
}
[data-rk] .ju367v50 {
    margin-top: 20px;
}
[data-rk] .ju367v51 {
    margin-top: 24px;
}
[data-rk] .ju367v52 {
    margin-top: 28px;
}
[data-rk] .ju367v53 {
    margin-top: 32px;
}
[data-rk] .ju367v54 {
    margin-top: 36px;
}
[data-rk] .ju367v55 {
    margin-top: 44px;
}
[data-rk] .ju367v56 {
    margin-top: 64px;
}
[data-rk] .ju367v57 {
    margin-top: -1px;
}
[data-rk] .ju367v58 {
    max-width: 1px;
}
[data-rk] .ju367v59 {
    max-width: 2px;
}
[data-rk] .ju367v5a {
    max-width: 4px;
}
[data-rk] .ju367v5b {
    max-width: 8px;
}
[data-rk] .ju367v5c {
    max-width: 12px;
}
[data-rk] .ju367v5d {
    max-width: 20px;
}
[data-rk] .ju367v5e {
    max-width: 24px;
}
[data-rk] .ju367v5f {
    max-width: 28px;
}
[data-rk] .ju367v5g {
    max-width: 30px;
}
[data-rk] .ju367v5h {
    max-width: 32px;
}
[data-rk] .ju367v5i {
    max-width: 34px;
}
[data-rk] .ju367v5j {
    max-width: 36px;
}
[data-rk] .ju367v5k {
    max-width: 40px;
}
[data-rk] .ju367v5l {
    max-width: 44px;
}
[data-rk] .ju367v5m {
    max-width: 48px;
}
[data-rk] .ju367v5n {
    max-width: 54px;
}
[data-rk] .ju367v5o {
    max-width: 60px;
}
[data-rk] .ju367v5p {
    max-width: 200px;
}
[data-rk] .ju367v5q {
    max-width: 100%;
}
[data-rk] .ju367v5r {
    max-width: -moz-max-content;
    max-width: max-content;
}
[data-rk] .ju367v5s {
    min-width: 1px;
}
[data-rk] .ju367v5t {
    min-width: 2px;
}
[data-rk] .ju367v5u {
    min-width: 4px;
}
[data-rk] .ju367v5v {
    min-width: 8px;
}
[data-rk] .ju367v5w {
    min-width: 12px;
}
[data-rk] .ju367v5x {
    min-width: 20px;
}
[data-rk] .ju367v5y {
    min-width: 24px;
}
[data-rk] .ju367v5z {
    min-width: 28px;
}
[data-rk] .ju367v60 {
    min-width: 30px;
}
[data-rk] .ju367v61 {
    min-width: 32px;
}
[data-rk] .ju367v62 {
    min-width: 34px;
}
[data-rk] .ju367v63 {
    min-width: 36px;
}
[data-rk] .ju367v64 {
    min-width: 40px;
}
[data-rk] .ju367v65 {
    min-width: 44px;
}
[data-rk] .ju367v66 {
    min-width: 48px;
}
[data-rk] .ju367v67 {
    min-width: 54px;
}
[data-rk] .ju367v68 {
    min-width: 60px;
}
[data-rk] .ju367v69 {
    min-width: 200px;
}
[data-rk] .ju367v6a {
    min-width: 100%;
}
[data-rk] .ju367v6b {
    min-width: -moz-max-content;
    min-width: max-content;
}
[data-rk] .ju367v6c {
    overflow: hidden;
}
[data-rk] .ju367v6d {
    padding-bottom: 0;
}
[data-rk] .ju367v6e {
    padding-bottom: 1px;
}
[data-rk] .ju367v6f {
    padding-bottom: 2px;
}
[data-rk] .ju367v6g {
    padding-bottom: 3px;
}
[data-rk] .ju367v6h {
    padding-bottom: 4px;
}
[data-rk] .ju367v6i {
    padding-bottom: 5px;
}
[data-rk] .ju367v6j {
    padding-bottom: 6px;
}
[data-rk] .ju367v6k {
    padding-bottom: 8px;
}
[data-rk] .ju367v6l {
    padding-bottom: 10px;
}
[data-rk] .ju367v6m {
    padding-bottom: 12px;
}
[data-rk] .ju367v6n {
    padding-bottom: 14px;
}
[data-rk] .ju367v6o {
    padding-bottom: 16px;
}
[data-rk] .ju367v6p {
    padding-bottom: 18px;
}
[data-rk] .ju367v6q {
    padding-bottom: 20px;
}
[data-rk] .ju367v6r {
    padding-bottom: 24px;
}
[data-rk] .ju367v6s {
    padding-bottom: 28px;
}
[data-rk] .ju367v6t {
    padding-bottom: 32px;
}
[data-rk] .ju367v6u {
    padding-bottom: 36px;
}
[data-rk] .ju367v6v {
    padding-bottom: 44px;
}
[data-rk] .ju367v6w {
    padding-bottom: 64px;
}
[data-rk] .ju367v6x {
    padding-bottom: -1px;
}
[data-rk] .ju367v6y {
    padding-left: 0;
}
[data-rk] .ju367v6z {
    padding-left: 1px;
}
[data-rk] .ju367v70 {
    padding-left: 2px;
}
[data-rk] .ju367v71 {
    padding-left: 3px;
}
[data-rk] .ju367v72 {
    padding-left: 4px;
}
[data-rk] .ju367v73 {
    padding-left: 5px;
}
[data-rk] .ju367v74 {
    padding-left: 6px;
}
[data-rk] .ju367v75 {
    padding-left: 8px;
}
[data-rk] .ju367v76 {
    padding-left: 10px;
}
[data-rk] .ju367v77 {
    padding-left: 12px;
}
[data-rk] .ju367v78 {
    padding-left: 14px;
}
[data-rk] .ju367v79 {
    padding-left: 16px;
}
[data-rk] .ju367v7a {
    padding-left: 18px;
}
[data-rk] .ju367v7b {
    padding-left: 20px;
}
[data-rk] .ju367v7c {
    padding-left: 24px;
}
[data-rk] .ju367v7d {
    padding-left: 28px;
}
[data-rk] .ju367v7e {
    padding-left: 32px;
}
[data-rk] .ju367v7f {
    padding-left: 36px;
}
[data-rk] .ju367v7g {
    padding-left: 44px;
}
[data-rk] .ju367v7h {
    padding-left: 64px;
}
[data-rk] .ju367v7i {
    padding-left: -1px;
}
[data-rk] .ju367v7j {
    padding-right: 0;
}
[data-rk] .ju367v7k {
    padding-right: 1px;
}
[data-rk] .ju367v7l {
    padding-right: 2px;
}
[data-rk] .ju367v7m {
    padding-right: 3px;
}
[data-rk] .ju367v7n {
    padding-right: 4px;
}
[data-rk] .ju367v7o {
    padding-right: 5px;
}
[data-rk] .ju367v7p {
    padding-right: 6px;
}
[data-rk] .ju367v7q {
    padding-right: 8px;
}
[data-rk] .ju367v7r {
    padding-right: 10px;
}
[data-rk] .ju367v7s {
    padding-right: 12px;
}
[data-rk] .ju367v7t {
    padding-right: 14px;
}
[data-rk] .ju367v7u {
    padding-right: 16px;
}
[data-rk] .ju367v7v {
    padding-right: 18px;
}
[data-rk] .ju367v7w {
    padding-right: 20px;
}
[data-rk] .ju367v7x {
    padding-right: 24px;
}
[data-rk] .ju367v7y {
    padding-right: 28px;
}
[data-rk] .ju367v7z {
    padding-right: 32px;
}
[data-rk] .ju367v80 {
    padding-right: 36px;
}
[data-rk] .ju367v81 {
    padding-right: 44px;
}
[data-rk] .ju367v82 {
    padding-right: 64px;
}
[data-rk] .ju367v83 {
    padding-right: -1px;
}
[data-rk] .ju367v84 {
    padding-top: 0;
}
[data-rk] .ju367v85 {
    padding-top: 1px;
}
[data-rk] .ju367v86 {
    padding-top: 2px;
}
[data-rk] .ju367v87 {
    padding-top: 3px;
}
[data-rk] .ju367v88 {
    padding-top: 4px;
}
[data-rk] .ju367v89 {
    padding-top: 5px;
}
[data-rk] .ju367v8a {
    padding-top: 6px;
}
[data-rk] .ju367v8b {
    padding-top: 8px;
}
[data-rk] .ju367v8c {
    padding-top: 10px;
}
[data-rk] .ju367v8d {
    padding-top: 12px;
}
[data-rk] .ju367v8e {
    padding-top: 14px;
}
[data-rk] .ju367v8f {
    padding-top: 16px;
}
[data-rk] .ju367v8g {
    padding-top: 18px;
}
[data-rk] .ju367v8h {
    padding-top: 20px;
}
[data-rk] .ju367v8i {
    padding-top: 24px;
}
[data-rk] .ju367v8j {
    padding-top: 28px;
}
[data-rk] .ju367v8k {
    padding-top: 32px;
}
[data-rk] .ju367v8l {
    padding-top: 36px;
}
[data-rk] .ju367v8m {
    padding-top: 44px;
}
[data-rk] .ju367v8n {
    padding-top: 64px;
}
[data-rk] .ju367v8o {
    padding-top: -1px;
}
[data-rk] .ju367v8p {
    position: absolute;
}
[data-rk] .ju367v8q {
    position: fixed;
}
[data-rk] .ju367v8r {
    position: relative;
}
[data-rk] .ju367v8s {
    -webkit-user-select: none;
}
[data-rk] .ju367v8t {
    right: 0;
}
[data-rk] .ju367v8u {
    transition: 0.125s ease;
}
[data-rk] .ju367v8v {
    transition: transform 0.125s ease;
}
[data-rk] .ju367v8w {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
[data-rk] .ju367v8x {
    width: 1px;
}
[data-rk] .ju367v8y {
    width: 2px;
}
[data-rk] .ju367v8z {
    width: 4px;
}
[data-rk] .ju367v90 {
    width: 8px;
}
[data-rk] .ju367v91 {
    width: 12px;
}
[data-rk] .ju367v92 {
    width: 20px;
}
[data-rk] .ju367v93 {
    width: 24px;
}
[data-rk] .ju367v94 {
    width: 28px;
}
[data-rk] .ju367v95 {
    width: 30px;
}
[data-rk] .ju367v96 {
    width: 32px;
}
[data-rk] .ju367v97 {
    width: 34px;
}
[data-rk] .ju367v98 {
    width: 36px;
}
[data-rk] .ju367v99 {
    width: 40px;
}
[data-rk] .ju367v9a {
    width: 44px;
}
[data-rk] .ju367v9b {
    width: 48px;
}
[data-rk] .ju367v9c {
    width: 54px;
}
[data-rk] .ju367v9d {
    width: 60px;
}
[data-rk] .ju367v9e {
    width: 200px;
}
[data-rk] .ju367v9f {
    width: 100%;
}
[data-rk] .ju367v9g {
    width: -moz-max-content;
    width: max-content;
}
[data-rk] .ju367v9h {
    -webkit-backdrop-filter: var(--rk-blurs-modalOverlay);
    backdrop-filter: var(--rk-blurs-modalOverlay);
}
[data-rk] .ju367v9i,
[data-rk] .ju367v9j:hover,
[data-rk] .ju367v9k:active {
    background: var(--rk-colors-accentColor);
}
[data-rk] .ju367v9l,
[data-rk] .ju367v9m:hover,
[data-rk] .ju367v9n:active {
    background: var(--rk-colors-accentColorForeground);
}
[data-rk] .ju367v9o,
[data-rk] .ju367v9p:hover,
[data-rk] .ju367v9q:active {
    background: var(--rk-colors-actionButtonBorder);
}
[data-rk] .ju367v9r,
[data-rk] .ju367v9s:hover,
[data-rk] .ju367v9t:active {
    background: var(--rk-colors-actionButtonBorderMobile);
}
[data-rk] .ju367v9u,
[data-rk] .ju367v9v:hover,
[data-rk] .ju367v9w:active {
    background: var(--rk-colors-actionButtonSecondaryBackground);
}
[data-rk] .ju367v9x,
[data-rk] .ju367v9y:hover,
[data-rk] .ju367v9z:active {
    background: var(--rk-colors-closeButton);
}
[data-rk] .ju367va0,
[data-rk] .ju367va1:hover,
[data-rk] .ju367va2:active {
    background: var(--rk-colors-closeButtonBackground);
}
[data-rk] .ju367va3,
[data-rk] .ju367va4:hover,
[data-rk] .ju367va5:active {
    background: var(--rk-colors-connectButtonBackground);
}
[data-rk] .ju367va6,
[data-rk] .ju367va7:hover,
[data-rk] .ju367va8:active {
    background: var(--rk-colors-connectButtonBackgroundError);
}
[data-rk] .ju367va9,
[data-rk] .ju367vaa:hover,
[data-rk] .ju367vab:active {
    background: var(--rk-colors-connectButtonInnerBackground);
}
[data-rk] .ju367vac,
[data-rk] .ju367vad:hover,
[data-rk] .ju367vae:active {
    background: var(--rk-colors-connectButtonText);
}
[data-rk] .ju367vaf,
[data-rk] .ju367vag:hover,
[data-rk] .ju367vah:active {
    background: var(--rk-colors-connectButtonTextError);
}
[data-rk] .ju367vai,
[data-rk] .ju367vaj:hover,
[data-rk] .ju367vak:active {
    background: var(--rk-colors-connectionIndicator);
}
[data-rk] .ju367val,
[data-rk] .ju367vam:hover,
[data-rk] .ju367van:active {
    background: var(--rk-colors-downloadBottomCardBackground);
}
[data-rk] .ju367vao,
[data-rk] .ju367vap:hover,
[data-rk] .ju367vaq:active {
    background: var(--rk-colors-downloadTopCardBackground);
}
[data-rk] .ju367var,
[data-rk] .ju367vas:hover,
[data-rk] .ju367vat:active {
    background: var(--rk-colors-error);
}
[data-rk] .ju367vau,
[data-rk] .ju367vav:hover,
[data-rk] .ju367vaw:active {
    background: var(--rk-colors-generalBorder);
}
[data-rk] .ju367vax,
[data-rk] .ju367vay:hover,
[data-rk] .ju367vaz:active {
    background: var(--rk-colors-generalBorderDim);
}
[data-rk] .ju367vb0,
[data-rk] .ju367vb1:hover,
[data-rk] .ju367vb2:active {
    background: var(--rk-colors-menuItemBackground);
}
[data-rk] .ju367vb3,
[data-rk] .ju367vb4:hover,
[data-rk] .ju367vb5:active {
    background: var(--rk-colors-modalBackdrop);
}
[data-rk] .ju367vb6,
[data-rk] .ju367vb7:hover,
[data-rk] .ju367vb8:active {
    background: var(--rk-colors-modalBackground);
}
[data-rk] .ju367vb9,
[data-rk] .ju367vba:hover,
[data-rk] .ju367vbb:active {
    background: var(--rk-colors-modalBorder);
}
[data-rk] .ju367vbc,
[data-rk] .ju367vbd:hover,
[data-rk] .ju367vbe:active {
    background: var(--rk-colors-modalText);
}
[data-rk] .ju367vbf,
[data-rk] .ju367vbg:hover,
[data-rk] .ju367vbh:active {
    background: var(--rk-colors-modalTextDim);
}
[data-rk] .ju367vbi,
[data-rk] .ju367vbj:hover,
[data-rk] .ju367vbk:active {
    background: var(--rk-colors-modalTextSecondary);
}
[data-rk] .ju367vbl,
[data-rk] .ju367vbm:hover,
[data-rk] .ju367vbn:active {
    background: var(--rk-colors-profileAction);
}
[data-rk] .ju367vbo,
[data-rk] .ju367vbp:hover,
[data-rk] .ju367vbq:active {
    background: var(--rk-colors-profileActionHover);
}
[data-rk] .ju367vbr,
[data-rk] .ju367vbs:hover,
[data-rk] .ju367vbt:active {
    background: var(--rk-colors-profileForeground);
}
[data-rk] .ju367vbu,
[data-rk] .ju367vbv:hover,
[data-rk] .ju367vbw:active {
    background: var(--rk-colors-selectedOptionBorder);
}
[data-rk] .ju367vbx,
[data-rk] .ju367vby:hover,
[data-rk] .ju367vbz:active {
    background: var(--rk-colors-standby);
}
[data-rk] .ju367vc0,
[data-rk] .ju367vc1:hover,
[data-rk] .ju367vc2:active {
    border-color: var(--rk-colors-accentColor);
}
[data-rk] .ju367vc3,
[data-rk] .ju367vc4:hover,
[data-rk] .ju367vc5:active {
    border-color: var(--rk-colors-accentColorForeground);
}
[data-rk] .ju367vc6,
[data-rk] .ju367vc7:hover,
[data-rk] .ju367vc8:active {
    border-color: var(--rk-colors-actionButtonBorder);
}
[data-rk] .ju367vc9,
[data-rk] .ju367vca:hover,
[data-rk] .ju367vcb:active {
    border-color: var(--rk-colors-actionButtonBorderMobile);
}
[data-rk] .ju367vcc,
[data-rk] .ju367vcd:hover,
[data-rk] .ju367vce:active {
    border-color: var(--rk-colors-actionButtonSecondaryBackground);
}
[data-rk] .ju367vcf,
[data-rk] .ju367vcg:hover,
[data-rk] .ju367vch:active {
    border-color: var(--rk-colors-closeButton);
}
[data-rk] .ju367vci,
[data-rk] .ju367vcj:hover,
[data-rk] .ju367vck:active {
    border-color: var(--rk-colors-closeButtonBackground);
}
[data-rk] .ju367vcl,
[data-rk] .ju367vcm:hover,
[data-rk] .ju367vcn:active {
    border-color: var(--rk-colors-connectButtonBackground);
}
[data-rk] .ju367vco,
[data-rk] .ju367vcp:hover,
[data-rk] .ju367vcq:active {
    border-color: var(--rk-colors-connectButtonBackgroundError);
}
[data-rk] .ju367vcr,
[data-rk] .ju367vcs:hover,
[data-rk] .ju367vct:active {
    border-color: var(--rk-colors-connectButtonInnerBackground);
}
[data-rk] .ju367vcu,
[data-rk] .ju367vcv:hover,
[data-rk] .ju367vcw:active {
    border-color: var(--rk-colors-connectButtonText);
}
[data-rk] .ju367vcx,
[data-rk] .ju367vcy:hover,
[data-rk] .ju367vcz:active {
    border-color: var(--rk-colors-connectButtonTextError);
}
[data-rk] .ju367vd0,
[data-rk] .ju367vd1:hover,
[data-rk] .ju367vd2:active {
    border-color: var(--rk-colors-connectionIndicator);
}
[data-rk] .ju367vd3,
[data-rk] .ju367vd4:hover,
[data-rk] .ju367vd5:active {
    border-color: var(--rk-colors-downloadBottomCardBackground);
}
[data-rk] .ju367vd6,
[data-rk] .ju367vd7:hover,
[data-rk] .ju367vd8:active {
    border-color: var(--rk-colors-downloadTopCardBackground);
}
[data-rk] .ju367vd9,
[data-rk] .ju367vda:hover,
[data-rk] .ju367vdb:active {
    border-color: var(--rk-colors-error);
}
[data-rk] .ju367vdc,
[data-rk] .ju367vdd:hover,
[data-rk] .ju367vde:active {
    border-color: var(--rk-colors-generalBorder);
}
[data-rk] .ju367vdf,
[data-rk] .ju367vdg:hover,
[data-rk] .ju367vdh:active {
    border-color: var(--rk-colors-generalBorderDim);
}
[data-rk] .ju367vdi,
[data-rk] .ju367vdj:hover,
[data-rk] .ju367vdk:active {
    border-color: var(--rk-colors-menuItemBackground);
}
[data-rk] .ju367vdl,
[data-rk] .ju367vdm:hover,
[data-rk] .ju367vdn:active {
    border-color: var(--rk-colors-modalBackdrop);
}
[data-rk] .ju367vdo,
[data-rk] .ju367vdp:hover,
[data-rk] .ju367vdq:active {
    border-color: var(--rk-colors-modalBackground);
}
[data-rk] .ju367vdr,
[data-rk] .ju367vds:hover,
[data-rk] .ju367vdt:active {
    border-color: var(--rk-colors-modalBorder);
}
[data-rk] .ju367vdu,
[data-rk] .ju367vdv:hover,
[data-rk] .ju367vdw:active {
    border-color: var(--rk-colors-modalText);
}
[data-rk] .ju367vdx,
[data-rk] .ju367vdy:hover,
[data-rk] .ju367vdz:active {
    border-color: var(--rk-colors-modalTextDim);
}
[data-rk] .ju367ve0,
[data-rk] .ju367ve1:hover,
[data-rk] .ju367ve2:active {
    border-color: var(--rk-colors-modalTextSecondary);
}
[data-rk] .ju367ve3,
[data-rk] .ju367ve4:hover,
[data-rk] .ju367ve5:active {
    border-color: var(--rk-colors-profileAction);
}
[data-rk] .ju367ve6,
[data-rk] .ju367ve7:hover,
[data-rk] .ju367ve8:active {
    border-color: var(--rk-colors-profileActionHover);
}
[data-rk] .ju367ve9,
[data-rk] .ju367vea:hover,
[data-rk] .ju367veb:active {
    border-color: var(--rk-colors-profileForeground);
}
[data-rk] .ju367vec,
[data-rk] .ju367ved:hover,
[data-rk] .ju367vee:active {
    border-color: var(--rk-colors-selectedOptionBorder);
}
[data-rk] .ju367vef,
[data-rk] .ju367veg:hover,
[data-rk] .ju367veh:active {
    border-color: var(--rk-colors-standby);
}
[data-rk] .ju367vei,
[data-rk] .ju367vej:hover,
[data-rk] .ju367vek:active {
    box-shadow: var(--rk-shadows-connectButton);
}
[data-rk] .ju367vel,
[data-rk] .ju367vem:hover,
[data-rk] .ju367ven:active {
    box-shadow: var(--rk-shadows-dialog);
}
[data-rk] .ju367veo,
[data-rk] .ju367vep:hover,
[data-rk] .ju367veq:active {
    box-shadow: var(--rk-shadows-profileDetailsAction);
}
[data-rk] .ju367ver,
[data-rk] .ju367ves:hover,
[data-rk] .ju367vet:active {
    box-shadow: var(--rk-shadows-selectedOption);
}
[data-rk] .ju367veu,
[data-rk] .ju367vev:hover,
[data-rk] .ju367vew:active {
    box-shadow: var(--rk-shadows-selectedWallet);
}
[data-rk] .ju367vex,
[data-rk] .ju367vey:hover,
[data-rk] .ju367vez:active {
    box-shadow: var(--rk-shadows-walletLogo);
}
[data-rk] .ju367vf0,
[data-rk] .ju367vf1:hover,
[data-rk] .ju367vf2:active {
    color: var(--rk-colors-accentColor);
}
[data-rk] .ju367vf3,
[data-rk] .ju367vf4:hover,
[data-rk] .ju367vf5:active {
    color: var(--rk-colors-accentColorForeground);
}
[data-rk] .ju367vf6,
[data-rk] .ju367vf7:hover,
[data-rk] .ju367vf8:active {
    color: var(--rk-colors-actionButtonBorder);
}
[data-rk] .ju367vf9,
[data-rk] .ju367vfa:hover,
[data-rk] .ju367vfb:active {
    color: var(--rk-colors-actionButtonBorderMobile);
}
[data-rk] .ju367vfc,
[data-rk] .ju367vfd:hover,
[data-rk] .ju367vfe:active {
    color: var(--rk-colors-actionButtonSecondaryBackground);
}
[data-rk] .ju367vff,
[data-rk] .ju367vfg:hover,
[data-rk] .ju367vfh:active {
    color: var(--rk-colors-closeButton);
}
[data-rk] .ju367vfi,
[data-rk] .ju367vfj:hover,
[data-rk] .ju367vfk:active {
    color: var(--rk-colors-closeButtonBackground);
}
[data-rk] .ju367vfl,
[data-rk] .ju367vfm:hover,
[data-rk] .ju367vfn:active {
    color: var(--rk-colors-connectButtonBackground);
}
[data-rk] .ju367vfo,
[data-rk] .ju367vfp:hover,
[data-rk] .ju367vfq:active {
    color: var(--rk-colors-connectButtonBackgroundError);
}
[data-rk] .ju367vfr,
[data-rk] .ju367vfs:hover,
[data-rk] .ju367vft:active {
    color: var(--rk-colors-connectButtonInnerBackground);
}
[data-rk] .ju367vfu,
[data-rk] .ju367vfv:hover,
[data-rk] .ju367vfw:active {
    color: var(--rk-colors-connectButtonText);
}
[data-rk] .ju367vfx,
[data-rk] .ju367vfy:hover,
[data-rk] .ju367vfz:active {
    color: var(--rk-colors-connectButtonTextError);
}
[data-rk] .ju367vg0,
[data-rk] .ju367vg1:hover,
[data-rk] .ju367vg2:active {
    color: var(--rk-colors-connectionIndicator);
}
[data-rk] .ju367vg3,
[data-rk] .ju367vg4:hover,
[data-rk] .ju367vg5:active {
    color: var(--rk-colors-downloadBottomCardBackground);
}
[data-rk] .ju367vg6,
[data-rk] .ju367vg7:hover,
[data-rk] .ju367vg8:active {
    color: var(--rk-colors-downloadTopCardBackground);
}
[data-rk] .ju367vg9,
[data-rk] .ju367vga:hover,
[data-rk] .ju367vgb:active {
    color: var(--rk-colors-error);
}
[data-rk] .ju367vgc,
[data-rk] .ju367vgd:hover,
[data-rk] .ju367vge:active {
    color: var(--rk-colors-generalBorder);
}
[data-rk] .ju367vgf,
[data-rk] .ju367vgg:hover,
[data-rk] .ju367vgh:active {
    color: var(--rk-colors-generalBorderDim);
}
[data-rk] .ju367vgi,
[data-rk] .ju367vgj:hover,
[data-rk] .ju367vgk:active {
    color: var(--rk-colors-menuItemBackground);
}
[data-rk] .ju367vgl,
[data-rk] .ju367vgm:hover,
[data-rk] .ju367vgn:active {
    color: var(--rk-colors-modalBackdrop);
}
[data-rk] .ju367vgo,
[data-rk] .ju367vgp:hover,
[data-rk] .ju367vgq:active {
    color: var(--rk-colors-modalBackground);
}
[data-rk] .ju367vgr,
[data-rk] .ju367vgs:hover,
[data-rk] .ju367vgt:active {
    color: var(--rk-colors-modalBorder);
}
[data-rk] .ju367vgu,
[data-rk] .ju367vgv:hover,
[data-rk] .ju367vgw:active {
    color: var(--rk-colors-modalText);
}
[data-rk] .ju367vgx,
[data-rk] .ju367vgy:hover,
[data-rk] .ju367vgz:active {
    color: var(--rk-colors-modalTextDim);
}
[data-rk] .ju367vh0,
[data-rk] .ju367vh1:hover,
[data-rk] .ju367vh2:active {
    color: var(--rk-colors-modalTextSecondary);
}
[data-rk] .ju367vh3,
[data-rk] .ju367vh4:hover,
[data-rk] .ju367vh5:active {
    color: var(--rk-colors-profileAction);
}
[data-rk] .ju367vh6,
[data-rk] .ju367vh7:hover,
[data-rk] .ju367vh8:active {
    color: var(--rk-colors-profileActionHover);
}
[data-rk] .ju367vh9,
[data-rk] .ju367vha:hover,
[data-rk] .ju367vhb:active {
    color: var(--rk-colors-profileForeground);
}
[data-rk] .ju367vhc,
[data-rk] .ju367vhd:hover,
[data-rk] .ju367vhe:active {
    color: var(--rk-colors-selectedOptionBorder);
}
[data-rk] .ju367vhf,
[data-rk] .ju367vhg:hover,
[data-rk] .ju367vhh:active {
    color: var(--rk-colors-standby);
}
@media screen and (min-width: 768px) {
    [data-rk] .ju367v1 {
        align-items: flex-start;
    }
    [data-rk] .ju367v3 {
        align-items: flex-end;
    }
    [data-rk] .ju367v5 {
        align-items: center;
    }
    [data-rk] .ju367v7 {
        display: none;
    }
    [data-rk] .ju367v9 {
        display: block;
    }
    [data-rk] .ju367vb {
        display: flex;
    }
    [data-rk] .ju367vd {
        display: inline;
    }
}
[data-rk] ._12cbo8i3,
[data-rk] ._12cbo8i3:after {
    --_12cbo8i0: 1;
    --_12cbo8i1: 1;
}
[data-rk] ._12cbo8i3:hover {
    transform: scale(var(--_12cbo8i0));
}
[data-rk] ._12cbo8i3:active {
    transform: scale(var(--_12cbo8i1));
}
[data-rk] ._12cbo8i3:active:after {
    content: "";
    bottom: -1px;
    display: block;
    left: -1px;
    position: absolute;
    right: -1px;
    top: -1px;
    transform: scale(calc((1 / var(--_12cbo8i1)) * var(--_12cbo8i0)));
}
[data-rk] ._12cbo8i4,
[data-rk] ._12cbo8i4:after {
    --_12cbo8i0: 1.025;
}
[data-rk] ._12cbo8i5,
[data-rk] ._12cbo8i5:after {
    --_12cbo8i0: 1.1;
}
[data-rk] ._12cbo8i6,
[data-rk] ._12cbo8i6:after {
    --_12cbo8i1: 0.95;
}
[data-rk] ._12cbo8i7,
[data-rk] ._12cbo8i7:after {
    --_12cbo8i1: 0.9;
}
@keyframes _1luule41 {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}
[data-rk] ._1luule42 {
    animation: _1luule41 3s linear infinite;
}
[data-rk] ._1luule43 {
    background: conic-gradient(
        from 180deg at 50% 50%,
        rgba(72, 146, 254, 0) 0deg,
        currentColor 282.04deg,
        rgba(72, 146, 254, 0) 319.86deg,
        rgba(72, 146, 254, 0) 1turn
    );
    height: 21px;
    width: 21px;
}
@keyframes _9pm4ki0 {
    0% {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes _9pm4ki1 {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
[data-rk] ._9pm4ki3 {
    animation: _9pm4ki1 0.15s ease;
    bottom: -200px;
    left: -200px;
    padding: 200px;
    right: -200px;
    top: -200px;
    transform: translateZ(0);
    z-index: 2147483646;
}
[data-rk] ._9pm4ki5 {
    animation: _9pm4ki0 0.35s cubic-bezier(0.15, 1.15, 0.6, 1),
        _9pm4ki1 0.15s ease;
    max-width: 100vw;
}
[data-rk] ._1ckjpok1 {
    box-sizing: content-box;
    max-width: 100vw;
    width: 360px;
}
[data-rk] ._1ckjpok2 {
    width: 100vw;
}
[data-rk] ._1ckjpok3 {
    min-width: 720px;
    width: 720px;
}
[data-rk] ._1ckjpok4 {
    min-width: 368px;
    width: 368px;
}
[data-rk] ._1ckjpok6 {
    border-width: 0;
    box-sizing: border-box;
    width: 100vw;
}
@media screen and (min-width: 768px) {
    [data-rk] ._1ckjpok1 {
        width: 360px;
    }
    [data-rk] ._1ckjpok2 {
        width: 480px;
    }
    [data-rk] ._1ckjpok4 {
        min-width: 368px;
        width: 368px;
    }
}
@media screen and (max-width: 767px) {
    [data-rk] ._1ckjpok7 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-top: -200px;
        padding-bottom: 200px;
        top: 200px;
    }
}
[data-rk] .v9horb0:hover {
    background: unset;
}
[data-rk] ._18dqw9x0,
[data-rk] ._18dqw9x1 {
    max-height: 456px;
    overflow-y: auto;
    overflow-x: hidden;
}
[data-rk] ._18dqw9x1 {
    scrollbar-width: none;
}
[data-rk] ._18dqw9x1::-webkit-scrollbar {
    display: none;
}
[data-rk] .g5kl0l0 {
    border-color: transparent;
}
[data-rk] ._1vwt0cg0 {
    background: #fff;
}
[data-rk] ._1vwt0cg2 {
    max-height: 454px;
    overflow-y: auto;
}
[data-rk] ._1vwt0cg3 {
    min-width: 287px;
}
[data-rk] ._1vwt0cg4 {
    min-width: 100%;
}
@keyframes _1am14411 {
    0% {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -283;
    }
}
[data-rk] ._1am14410 {
    overflow: auto;
    scrollbar-width: none;
    transform: translateZ(0);
}
[data-rk] ._1am14410::-webkit-scrollbar {
    display: none;
}
[data-rk] ._1am14412 {
    animation: _1am14411 1s linear infinite;
    stroke-dasharray: 98 196;
    fill: none;
    stroke-linecap: round;
    stroke-width: 4;
}
[data-rk] ._1am14413 {
    position: absolute;
}
[data-rk] ._1y2lnfi0 {
    border: 1px solid rgba(16, 21, 31, 0.06);
}
[data-rk] ._1y2lnfi1 {
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.css-17pwl6t {
  display: flex;
  width: 100vw;
  height: var(--chakra-vh);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: var(--chakra-zIndices-modal);
  -webkit-box-pack: center;
  justify-content: center;
}

.css-79z5gx {
  padding-inline-start: var(--chakra-space-6);
  padding-inline-end: var(--chakra-space-6);
  padding-top: var(--chakra-space-2);
  padding-bottom: var(--chakra-space-2);
  flex: 1 1 0%;
  overflow: auto;
}

.css-3gumf9 {
  outline: transparent solid 2px;
  outline-offset: 2px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: var(--close-button-size);
  height: var(--close-button-size);
  border-radius: var(--chakra-radii-md);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  background: var(--close-button-bg);
  --close-button-size: var(--chakra-sizes-8);
  font-size: var(--chakra-fontSizes-xs);
  position: absolute;
  top: var(--chakra-space-2);
  right: var(--chakra-space-3);
  color: rgb(255, 255, 255);
}

.css-onkibi {
  width: 1em;
  height: 1em;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: currentcolor;
  vertical-align: middle;
}

.css-9fgtzh {
  flex: 0 1 0%;
  padding-inline-start: var(--chakra-space-6);
  padding-inline-end: var(--chakra-space-6);
  padding-top: var(--chakra-space-4);
  padding-bottom: var(--chakra-space-4);
  font-size: var(--chakra-fontSizes-xl);
  font-weight: var(--chakra-fontWeights-semibold);
}